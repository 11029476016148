import React from 'react'
import _ from 'lodash'
import { ScrollLink } from 'react-scroll'
import AnimationLink from './Link'


export const TextGroup = props => {
  return (
    <div {...props.rootProps}>
      {!props.childrenAfter && props.children}
      {props.title && <Title {...props.title} />}
      {props.description && <Description {...props.description} />}
      {props.cta && <CTA {...props.cta} />}
      {props.childrenAfter && props.children}
    </div>
  )
}

export const WorkGroup = props => (
  <div {...props.rootProps}>
    {props.title && <Title {...props.title} />}
    {props.workTypes && <WorkTypes {...props.workTypes} />}
    {props.description && <Description {...props.description} />}
  </div>
)

export const TileGroup = props => (
  <AnimationLink
    hex="#322EFF"
    paintDrip
    to={props.link.to}
    id={props.link.id}
  >
    <div {...props.rootProps}>
      {props.image && <Image {...props.image} />}
      {props.title && <Title {...props.title} />}
      {props.description && <Description {...props.description} />}
    </div>
  </AnimationLink>
)

export const Title = props => {
  switch (props.type) {
    case 'h1':
      return <h1 {...props.rootProps}>{props.text}</h1>
    case 'h2':
      return <h2 {...props.rootProps}>{props.text}</h2>
    case 'h3':
      return <h3 {...props.rootProps}>{props.text}</h3>
    case 'h4':
      return <h4 {...props.rootProps}>{props.text}</h4>
    case 'h5':
    default:
      return <h5 {...props.rootProps}>{props.text}</h5>
  }
}

export const Image = props => {
  return (
    <div className="image" {...props.rootProps}>
      <img
        src={props.image}
        width={props.width ?? 'auto'}
        height={props.height ?? 'auto'}
        title={props.title ?? ''}
        alt={props.title ?? ''}
        className={props.className ?? ''}
        loading={props.loading ?? ''}
      />
    </div>
  )
}

export const Description = props => <p {...props.rootProps}> {props.text}</p>

export const CTA = props => {
  switch (props.type) {
    case 'scrolllink':
      return (
        <ScrollLink {...props.rootProps}>
          {props.prefixIcon} {props.text} {props.suffixIcon}
        </ScrollLink>
      )
    case 'button':
      return (
        <button {...props.rootProps}>
          {props.prefixIcon} {props.text} {props.suffixIcon}
        </button>
      )
    case 'a':
    default:
      return (
        <AnimationLink
          hex="#322EFF"
          paintDrip
          {...props.rootProps}
        >
          {props.prefixIcon} {props.text} {props.suffixIcon}
        </AnimationLink>
      )
  }
}

export const WorkTypes = props => (
  <div {...props.rootProps} className={'worktypes'}>
    {_.map(props.items, (item, i) => (
      <p key={`workType-${i}`}>{item}</p>
    ))}
  </div>
)

export default TextGroup
